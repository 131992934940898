import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
const RenewSubscription = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const SendToken = () => {
    const userSubscriptions = JSON.parse(localStorage.getItem('userInfo'))
    window["dataLayer"].push({ event: "renew_clicked", parameter_screen: props.screen });
    userSubscriptions?.subscriptions?.map(el => {
      el.isExpired == true &&
      navigate({pathname:"/subscription-payment", search: createSearchParams({plan: el.token}).toString()})
    })
  }
  return (
    <div>
      <Button onClick={SendToken} className={`btn w-100 px-2 px-sm-4 py-2  englease__border--main englease__bg--main englease__txt--white englease__radius--f-8  englease englease--f-14`}>
        <span className="englease englease--f-14">{t("renewal.btn")}</span>
      </Button>
    </div>
  );
};
export default RenewSubscription;