import Axios from "../Axios";
import { ClassesMapper, ClassTestsMapper } from "../mappers/Classes";
import { errorHandler } from "../utils";
import ServiceHandler from "../utils/ServiceHandler";

async function getClassesByID(courseID) {
	try {
		const classes = await Axios.post(`api/classes`, courseID);
		return classes.data.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}
async function getScheduleClasses(courseData) {
	const params = new URLSearchParams(courseData);
	try {
		const classes = await Axios.get(`api/classes/schedule`, { params });
		return classes.data.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}
async function getAllClasses(allCourseData) {
	const params = new URLSearchParams(allCourseData);
	return await ServiceHandler(
		"get",
		`api/classes/all`,
		{ params },
		ClassesMapper
	);
}

async function getClassDetails(classId) {
	return await ServiceHandler(
		"get",
		`api/classes/detailsV2/${classId}`,
		null,
		ClassTestsMapper
	);
}

async function getUserLevel(scheduledId, classTypeId) {
	try {
		const userLevel = await Axios.get(
			`api/classes/user/progress?scheduled_class_type_id=${scheduledId}&class_type_id=${classTypeId}`
		);
		return userLevel?.data?.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

async function joinClass(classId) {
	try {
		const classMeeting = await Axios.post(`api/classes/join/`, {
			class_id: classId,
		});
		return classMeeting?.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

async function bookClass(classId) {
	try {
		const booked = await Axios.post(`api/classes/book`, classId);
		return booked?.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

async function postDownloadedMaterialCount(classId) {
	const data = { class_id: +classId };
	return await ServiceHandler(
		"post",
		`api/classes/download-material/`,
		data,
		null
	);
}

export {
	getClassesByID,
	getClassDetails,
	getUserLevel,
	joinClass,
	bookClass,
	getScheduleClasses,
	getAllClasses,
	postDownloadedMaterialCount,
};
