import React from "react";
import { getCookie } from "../../utils";
import "./styles.scss";

const PracticeVideos = () => {
	const detectLang = getCookie("i18next");
	return (
		<>
			<iframe
				className="iframe--holder"
				allowFullScreen={true}
				src={`${process.env.REACT_APP_BASE_URL}${detectLang}/practice-videos-list?headless=true`}
			></iframe>
		</>
	);
};
export default PracticeVideos;
