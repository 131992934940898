import ServiceHandler from "../utils/ServiceHandler";
import { errorHandler } from "../utils";

async function getEnterpriseDetails() {
	const corp = localStorage.getItem("corp-name");
	try {
		return await ServiceHandler(
			"get",
			`api/enterprise/theme?name=${corp ? corp : ""}`,
			null,
			null
		);
	} catch (e) {
		errorHandler(e.response.status);
		// console.log(e.message);
	}
}

export { getEnterpriseDetails };
