export const StudentMapper = (student) => {
	// let groupFlag = 0,
	// 	privateFlag = 0,
	// 	prerecordedFlag = 0;
	// const subscriptionArray = student?.subscriptions?.map((item) => {
	// 	item.isGroup === true && groupFlag === 0 && item.isExpired === false
	// 		? (groupFlag += 1)
	// 		: (item.isGroup = false);
	// 	item.isPrivate === true && privateFlag === 0 && item.isExpired === false
	// 		? (privateFlag += 1)
	// 		: (item.isPrivate = false);
	// 	item.isPreRecordedVideos === true &&
	// 	prerecordedFlag === 0 &&
	// 	item.isExpired === false
	// 		? (prerecordedFlag += 1)
	// 		: (item.isPreRecordedVideos = false);
	// 	return item;
	// });

	const studentObject = {
		id: student?.user_id,
		type: student?.type,
		name: student?.name,
		phone: student?.phone,
		email: student?.email,
		dob: student?.birthday,
		gender: student?.gender,
		lang: student?.language,
		courses: student?.courses,
		classes: student?.classes,
		lastName: student?.last_name,
		firstName: student?.first_name,
		countryId: student?.country_id,
		nationalityId: student?.nationality_id,
		companyName: student?.company_name,
		isSubscribed: student?.is_subscribed,
		preRecordedLevelId: student?.prerecorded_level_id || "",
		packageEndDate: student?.subscriptions?.end_date,
		packageName: student?.subscriptions?.package_name,
		packagePrice: student?.subscriptions?.package_price,
		packageStartDate: student?.subscriptions?.start_date,
		levelId: student?.level?.id || null,
		levelName: student?.level?.name || null,
		levelDescription: student?.level?.description || null,
		levelAbbreviation: student?.level?.abbreviation || null,
		subscriptions: student?.subscriptions,
		preOrderId: student?.preorder_id,
		leadId: student?.lead_id,
		isEb: student?.subscriptions?.find((el) => el.lessonTypeId == "19"),
		enterpriseObj: {
			enterprise_name: student?.enterprise?.enterprise_name,
			program_status: student?.enterprise?.program_status,
			student_status: student?.enterprise?.student_status,
			university: student?.enterprise?.university,
			is_commitment_signed: student?.enterprise?.is_commitment_signed,
			remaining_subscription_days: student?.remaining_subscription_days || null,
		},
	};
	return studentObject;
};
export const UserMapper = (student) => {
	const studentData = {
		levels: student?.levels,
		user: StudentMapper(student?.user),
		preOrderId: student?.preorder_id,
	};
	return studentData;
};

// export const subscriptionsMapper = (subscriptions) =>{
// 	return subscriptions.map((subscription) => {
// 		switch (subscription.lessonTypeId) {
// 			case 3:
// 				return {...subscription, lessonTypeId: 1}
// 			case 4:
// 				return {...subscription, lessonTypeId: 2}
// 			case 10:
// 				return {...subscription, lessonTypeId: 3}
// 			default:
// 				return
// 		}
// 	})
// }
