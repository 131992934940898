import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

import Messages from "../../components/reusable-components/Messages";
import StudentHero from "../../components/DashboardComponent/StudentHero";
import LoaderComponent from "../../components/reusable-components/LoaderComponent";
import RenewSubscription from "../../components/reusable-components/RenewSubscriptionsButton";
import StudentLearningProgress from "../../components/DashboardComponent/StudentLearningProgress";

import { getSurvey } from "../../services/surveyServices";
import { getLevel } from "../../services/AssessmentService";
import {
	getDashboardData,
	getNearestClass,
} from "../../services/dashboardService.js";

import "./styles.scss";

const Dashboard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [message, setMessage] = useState();
	const [success, setSuccess] = useState();
	const [surveyMsg, setSurveyMsg] = useState({});
	const [levelsList, setLevelsList] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [subsStatus, setSubsStatus] = useState({});
	const [timeIsOut, setTimeIsOut] = useState(false);
	const [userSurvey, setUserSurvey] = useState(null);
	const [nearestClass, setNearestClass] = useState({});
	const [userDashboard, setUserDashboard] = useState({});

	const getLevelsList = async () => {
		const levels = await getLevel();
		setLevelsList(levels.data);
	};

	const getData = async () => {
		const dashboardData = await getDashboardData();
		!dashboardData
			? setIsLoading(true)
			: (setIsLoading(false), setUserDashboard(dashboardData));
		localStorage.setItem("pre_order_id", dashboardData?.user?.preOrderId);
		localStorage.setItem("name", dashboardData?.user?.name);
		localStorage.setItem("email", dashboardData?.user?.email);
		localStorage.setItem("leadId", dashboardData?.user?.leadId);
		localStorage.setItem("level", dashboardData?.user?.levelAbbreviation);
		localStorage.setItem(
			"prerecordedLevelId",
			dashboardData?.user?.preRecordedLevelId
		);
		dispatch(actions.userData(dashboardData?.user));
		const userSubs = [];
		dashboardData?.user?.subscriptions.map((element) => {
			const data = {
				isPrivate: element.isPrivate,
				isGroup: element.isGroup,
				isPreRecordedVideos: element.isPreRecordedVideos,
				isExpired: element.isExpired,
				packTK: element.token,
			};
			setSubsStatus(data);
			return userSubs.push(data);
		});
		return localStorage.setItem("userSubscribed", JSON.stringify(userSubs));
	};

	const getNearClass = async () => {
		const nearestClass = await getNearestClass();
		setNearestClass(nearestClass);
	};

	const getStudentSurvey = async () => {
		const getStudentSurvey = await getSurvey();
		if (getStudentSurvey?.data?.status == true) {
			setUserSurvey(false);
			setSurveyMsg(getStudentSurvey.data.reflection);
		} else {
			setUserSurvey(null);
		}
	};

	const closeMssage = () => {
		setMessage(null);
	};

	function changeTime(remainingTime) {
		if (remainingTime === 0) {
			setTimeIsOut(true);
		}
	}
	useEffect(() => {
		getData();
		getLevelsList();
		getNearClass();
		getStudentSurvey();
	}, [timeIsOut, success]);

	const startSurvey = () => {
		window["dataLayer"].push({
			event: "survey_clicked",
			parameter_screen: "dashboard",
		});
		navigate("/survey");
	};

	return (
		<div className="h-100 px-md-3">
			<div className="mb-4">
				{userDashboard?.user?.subscriptions.map((subs, idx) => {
					return (
						<div key={idx}>
							{subs?.isExpired === true && (
								<Messages
									status={false}
									screen={"dashboard"}
									flag="iET-expired-warning"
									classType={"renewal"}
									childButton={<RenewSubscription token={subs?.token} screen={"dashboard"}/>}>
										{subs?.isExpired === true && (
											<div className="d-flex mb-lg-0 englease englease__txt--black englease--f-14 ">
												<span>
													{t("message.text")}
													<NavLink
														className="my-0 me-1 p-0 englease__txt--main message__decoration"
														to={"/support"}
													>
														{t("message.link")}
													</NavLink>
													{t("message.helper")}
												</span>
											</div>
										)}
								</Messages>
							)}
						</div>
					);
				})}
				{userSurvey !== null && (
					<Messages
						status={userSurvey}
						screen={"dashboard"}
						classType={"renewal"}
						flag="iET-expired-warning"
						children={
							<div className="mb-lg-0 englease englease--f-14">
							<p className="mb-0 englease--bold">{surveyMsg?.name}</p>
							<p className="mb-0">{surveyMsg?.description}</p>
						</div>
						}
						childButton={<button
							onClick={startSurvey}
							className={`btn w-100  px-0 px-md-4 py-2 englease__border--main englease__bg--main englease__txt--white englease__radius--f-8  englease englease--f-14 btn`}
						>
							<span className="englease englease--f-14">
							{t("survey.start-survey")}
							</span>
						</button>}
					>
					</Messages>
				)}
				{message && (
					<Messages
						status={success}
						screen={"dashboard"}
						classType={'profile'}
						flag="iET-Close-Message"
						onClick={closeMssage}
					>
						<p className="m-0 englease englease--f-14 ">{message}</p>
					</Messages>
				)}
			</div>
			<div>
				<p className="mb-0 englease--bold  englease--f-24">
					{t("dashBoard.title")}
				</p>
			</div>
			{/* Student Name And Current Level [Section] */}
			{isLoading ? (
				<LoaderComponent />
			) : (
				<div className="imET-dashboardPattern dashboard__image englease__bg--smoke englease__radius--f-8 w-100 py-4 px-2 px-md-4 mt-4">
					<div className="row dashboard__title ">
						<div className="col-12 ">
							{/* Dashboard Title And SubTitle */}
							<p className="mb-0 englease--f-24">
								{t("dashBoard.greeting")}
								<span className="englease--bold englease--f-24">
									{userDashboard?.user?.name}!
								</span>
							</p>
							<p className="mb-0 ps-4 englease--f-14">
								{t("dashBoard.classLevel")} :
								<span className="englease--bold  englease--f-14">
									<span className="mx-1">{userDashboard?.user?.levelName}</span>
								</span>
							</p>
						</div>
					</div>
					<div className="row dashboard__classes-holder ">
						{userDashboard?.user?.subscriptions?.map((item, idx) => (
							<StudentHero
								key={idx}
								item={item}
								nearestClass={nearestClass}
								message={setMessage}
								success={setSuccess}
								updateStatus={changeTime}
							/>
						))}
					</div>
				</div>
			)}
			{/* Student Progress Level [Section] */}
			<div className="w-100">
				{isLoading ? (
					<div style={{ maxWidth: "20%", maxHeight: "20%" }}>
						<LoaderComponent />
					</div>
				) : (
					<StudentLearningProgress
						subscribStatus={subsStatus}
						currentLevelStats={userDashboard?.levels[0]}
						levels={levelsList}
						// levels={userDashboard?.levels}
						currentLevel={userDashboard?.user?.levelId}
					/>
				)}
			</div>
		</div>
	);
};
export default Dashboard;
