import { useState } from "react";

export default function useToken() {
	let storedToken = localStorage.getItem("token");
	const getToken = () => {
		const tokenString =
			storedToken === null ||
			storedToken === undefined ||
			storedToken === "undefined"
				? ""
				: JSON.parse(storedToken);
		// const userToken = tokenString;
		return tokenString;
	};

	const [token, setToken] = useState(getToken());

	const saveToken = (userToken) => {
		localStorage.setItem("token", JSON.stringify(userToken));
		userToken === undefined || userToken === "undefined"
			? setToken("")
			: setToken(userToken.token);
	};

	return {
		setToken: saveToken,
		token,
	};
}
