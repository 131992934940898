import React from "react";
import "./styles.scss";
const Messages = (props) => {
	const { status, flag, onClick, children, classType, childButton } = props;
	return (
		<>
			{/* User Feedback Section */}
			{status !== null && (
				<div
					className={`row mx-0 d-flex align-items-center justify-content-between ${
						status === true
							? "englease__bg--success--20"
							: "englease__bg--error"
					} mb-4 py-3 px-3 englease__radius--f-8`}
				>
					<div className={`col-xs-12 col-sm message message__${classType}`}>
						<div className="d-flex ">
							<i
								className={`${
									status === true
										? "iET-Success-Message"
										: `${flag} ${onClick ? "englease--click" : ""}`
								} iET-s21`}
								style={{
									backgroundSize: "contain",
									backgroundRepeat: "no-repeat",
								}}
								onClick={onClick}
							></i>
						</div>
						<div className="">{children}</div>
					</div>
					{childButton && (
						<div className="col-xs-12 col-sm-3">{childButton}</div>
					)}
				</div>
			)}
		</>
	);
};
export default Messages;
