import React from "react";
import { useField } from "formik";
import "./styles.scss";
const InputField = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<div>
			<div
				className={`input-field ${
					props.page === "profile"
						? "outline-input-container"
						: "input-container"
				}`}
			>
				{props?.fieldtype === "text-area" ? (
					<textarea
						className={`${props.className}`}
						type="text"
						{...field}
						{...props}
					></textarea>
				) : props?.fieldtype === "select" ? (
					<select {...field} {...props}>
						{props.children}
						{props?.list.map((item) => (
							<option key={item.title}>{item.title}</option>
						))}
					</select>
				) : (
					<input {...field} {...props} aria-labelledby={props.id} />
				)}
				<label
					className={`${props?.fieldtype === "text-area" ? "" : "label-input"}`}
					htmlFor={props.id || props.name}
				>
					<div
						className={`text-input  ${
							props.labelcolor ? props.labelcolor : "englease__txt--black"
						} englease englease--f-14`}
					>
						{label}
					</div>
				</label>
			</div>
			{meta.touched && meta.error ? (
				<div className="mt-2 error-message englease englease__txt--error englease--f-12">
					{meta.error}
				</div>
			) : null}
		</div>
	);
};
export default React.memo(InputField);
