import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getResults } from "../../../services/AssessmentService";
import "./styles.scss";

const QuestionsAnswer = (props) => {
	const { t } = useTranslation();
	const [result, setResult] = useState();
	const [rightAnswer, setRightAnswer] = useState();
	const [currentAnswer, setCurrentAnswer] = useState();

	const param = useParams();
	// Get Results list from service.
	const AssessmentsLvl = async () => {
		const data = await getResults(param.id);
		setResult(data);
	};

	const getCorrectAnswer = (e, answer) => {
		setCurrentAnswer(e.target.title);
		setRightAnswer(answer);
	};

	const goBack = () => {
		history.back();
	};

	const answersConverter = (answerObj, userAnswer) => {
		let res;
		switch (answerObj?.question_type) {
			case 1:
			case 2:
				res = answerObj?.question.find((el) => el.id === userAnswer);
				return res.value;
			case 13:
			case 14:
				res = answerObj?.question.find((el) => el.id === userAnswer);
				return (
					<img
						width={"100px"}
						height={"100px"}
						src={`${res.attachment.media}`}
						className="questionsAnswer__media-attachment"
					/>
				);
			default:
				res =
					typeof userAnswer === "object"
						? userAnswer?.is_true === 1
							? "True"
							: "false"
						: userAnswer;
				return res;
		}
	};

	useEffect(() => {
		AssessmentsLvl();
	}, [param?.name]);

	return (
		<div className="questionsAnswer">
			<div className="row justify-content-center py-4 ">
				{/* Questions Answer [Parent Holder] */}
				<div className="questionsAnswer__holder col-lg-7 px-3 px-lg-5 englease__bg--white englease__radius--f-4">
					{/* Questions Title And Subtitle [Section] */}
					<div className="py-4 text-center">
						<p className="mb-4 englease--bold englease--f-24">
							{param?.name == "homework-result"
								? t("questions-answer.homework-title")
								: t("questions-answer.exam-title")}
						</p>
						<p className="m-0 englease englease--f-20">
							{param?.name == "homework-result"
								? t("questions-answer.homework-sub-title")
								: t("questions-answer.exam-sub-title")}
						</p>
					</div>
					{/* Questions And Answers [Section] */}
					<div className="questionsAnswer__section w-100">
						{/* Search Inout [Section] */}
						<div className="questionsAnswer__input englease__border--mute  englease__radius--f-4 ">
							<p className="m-0 englease__txt--muteTxt ">
								{result?.test_title}
							</p>
						</div>
						<div>
							<div className="d-flex flex-column gap-4">
								{param?.name == "homework-result" && (
									<>
										{result?.questions?.map((elem, idx) => {
											return (
												<div key={idx} className="row">
													<div className="uET-border-bottom-s1 d-flex flex-column flex-sm-row align-items-center gap-3 pb-2">
														<div className="col">
															<i
																className={`${
																	elem.is_correct === true
																		? "iET-Correct-Answer"
																		: "iET-Wrong-Answer"
																} iET-s24 englease__bg--contain`}
															></i>
														</div>
														<div className="col-11 d-flex flex-column justify-content-center align-items-start gap-2 englease englease--f-16 mb-3">
															<div
																className="d-flex flex-wrap"
																dangerouslySetInnerHTML={{
																	__html: `${
																		// typeof elem?.user_answer[0] === "object"
																		elem?.question_type === 3 ||
																		elem?.question_type === 6 ||
																		elem?.question_type === 7 ||
																		elem?.question_type === 8
																			? elem?.question?.map((el, idx) => {
																					return `<span
																							key=${idx}
																							className="mx-1 d-block"
																						>
																						${el?.value?.replaceAll("#", " [ ] ")}
																						</span>`;
																			  })
																			: elem?.title?.replaceAll("#", " [ ] ")
																	}`,
																}}
															></div>
															<div className="mb-0 d-flex flex-wrap gap-2">
																<span className="englease__txt--muteTxt">
																	{t("questions-answer.answer")} :
																</span>
																{elem?.user_answer?.map((el, idx) => {
																	return (
																		<p className="gap-2 mb-0" key={idx}>
																			{answersConverter(elem, el)}
																		</p>
																	);
																})}
															</div>
															{elem.is_correct !== true && (
																<>
																	<div className="d-flex justify-content-between align-items-center w-100">
																		<button
																			id={elem.id}
																			onClick={(e) =>
																				getCorrectAnswer(
																					e,
																					elem?.question_answer
																				)
																			}
																			title={idx}
																			className="englease__bg--main englease englease--f-16 englease__txt--white  px-3 py-1 englease__radius--f-4 englease__border--main"
																		>
																			{t("questions-answer.btn")}
																		</button>
																	</div>
																	{currentAnswer == idx && (
																		<div className="mb-0 d-flex flex-wrap gap-2 englease__txt--success">
																			{rightAnswer?.map((el, idx) => {
																				return (
																					<p
																						className="gap-2  englease__bg--success--20 mt-2 p-1  englease__radius--f-4"
																						key={idx}
																					>
																						{answersConverter(elem, el)}
																					</p>
																				);
																			})}
																		</div>
																	)}
																</>
															)}
														</div>
													</div>
												</div>
											);
										})}
									</>
								)}
								{param?.name == "exam-result" && (
									<>
										{result?.questions?.map((el, idx) => {
											return (
												<div key={idx} className="row">
													<div className="uET-border-bottom-s1 d-flex flex-column flex-sm-row align-items-center gap-3 pb-2">
														<div className="col">
															<i
																className={`${
																	el.is_correct === true
																		? "iET-Correct-Answer"
																		: "iET-Wrong-Answer"
																} iET-s24 englease__bg--contain`}
															></i>
														</div>
														<div className="col-11 d-flex flex-column justify-content-center align-items-start gap-2 englease englease--f-16 mb-3">
															<div
																className="d-flex flex-wrap"
																dangerouslySetInnerHTML={{
																	__html: `${
																		// typeof el?.user_answer[0] === "object"
																		el?.question_type === 3 ||
																		el?.question_type === 6 ||
																		el?.question_type === 7 ||
																		el?.question_type === 8
																			? el?.question?.map((el, idx) => {
																					return `<span
																							key=${idx}
																							className="mx-1 d-flex"
																						>
																						${el?.value?.replaceAll("#", " [ ] ")}
																						</span>`;
																			  })
																			: el?.title?.replaceAll("#", " [ ] ")
																	}`,
																}}
															></div>
															<p className="m-0">
																<span className="englease__txt--muteTxt">
																	{t("questions-answer.answer")}
																</span>
																{el?.user_answer?.map((answerItem, idx) => {
																	return (
																		<span className="mx-1" key={idx}>
																			{answersConverter(el, answerItem)}
																		</span>
																	);
																})}
															</p>
														</div>
													</div>
												</div>
											);
										})}
									</>
								)}
							</div>
							<div className="d-flex justify-content-center align-items-center my-4 ">
								<div
									className="py-2 px-4 englease englease--f-16 text-center englease--click englease__border--main
                 englease__radius--f-8 englease__txt--main"
									onClick={goBack}
								>
									{t("questions-answer.go-back")}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default QuestionsAnswer;
