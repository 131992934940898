import React from "react";
import { Modal } from "react-bootstrap";
import "./styles.scss";

const ConfirmationModal = (props) => {
	//a reusable component that receive props of kind:
	// [title => if existed / head => for having head to content / content => content of modal it could be holding any elements
	//  modal buttons => name and actions / size of modal => lg,md,sm]
	const localLang = localStorage.getItem("i18nextLng");

	return (
		<Modal
			{...props}
			animation={false}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop="static"
			// keyboard={false}
		>
			{props.closebutton == false && (
				<Modal.Header
					closeButton={props.closebutton || "true"}
					className={`${props.title ? "" : "border-0"}`}
				>
					{props.title && (
						<Modal.Title id="contained-modal-title-vcenter">
							{props.title}
						</Modal.Title>
					)}
				</Modal.Header>
			)}
			<Modal.Body
				className={`my-3 py-0 ${
					localLang === "ar" ? "text-end" : "text-start"
				}`}
			>
				<h4>{props.head}</h4>
				<section>{props.children}</section>
			</Modal.Body>

			{props.confirm !== null && (
				<Modal.Footer className="row d-flex justify-content-around">
					<div className="col-5">
						<button
							className={`${props.addclasses} ${
								props.type === "exitTest" ? "modal-exit-btn " : "modal-btn"
							}  w-100`}
							onClick={props.confirm}
						>
							{props.ok}
						</button>
					</div>
					<div className="col-5">
						<button
							className={`w-100 confirm-btn  englease englease--f-14 englease__radius--f-4
					`}
							onClick={props.onHide}
						>
							{props.cancel}
						</button>
					</div>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default ConfirmationModal;
