import * as yup from "yup";
import { t } from "i18next";

const emailRules = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// Login Validation
const loginValidation = () =>
	yup.object().shape({
		email: yup
			.string()
			.email(t("login.login-email-valid"))
			.matches(emailRules, t("login.login-email-match"))
			.required(t("login.login-email-required")),
		password: yup
			.string()
			.min(6, t("login.login-password-match"))
			.required(t("login.login-password-required")),
	});

// Forget Password Validation
const forgetPasswordValidation = () =>
	yup.object().shape({
		email: yup
			.string()
			.email()
			.matches(emailRules, t("forget-password.forget-password-email-valid"))
			.required(t("forget-password.forget-password-email-required")),
	});

// Reset Password Validation
const resetPasswordValidation = () =>
	yup.object().shape({
		password: yup
			.string()
			.min(6, t("student-profile.change-password.change-password-match"))
			.required(t("reset-password.reset-password-email-required")),
	});

// Student Profile Change Password Validation
const chnagePasswordValidation = () =>
	yup.object().shape({
		password: yup
			.string()
			.min(6, t("student-profile.change-password.change-password-match"))
			.required(t("student-profile.change-password.change-password-msg")),
		new_password: yup
			.string()
			.min(6, t("student-profile.change-password.change-password-new-match"))
			.required(t("student-profile.change-password.change-password-new-msg")),
	});

yup.addMethod(yup.string, "stripEmptyString", function () {
	return this.transform((value) => (value === "" ? undefined : value));
});

// Support Validation
const supportValidation = (data) =>
	yup.object().shape({
		title: yup
			.string()
			.min(2, `* ${t("support.support-title-validation")}`)
			.required(`* ${t("support.support-required-title")}`),
		message: yup
			.string()
			.min(10, `* ${t("support.support-validation-msg")}`)
			.required(`* ${t("support.support-required-msg")}`),
	});

// know-you Validation
const knowYouValidation = () =>
	yup.object().shape({
		gender: yup.number().required(t("know-you.gender-validation-msg")),
		// employment: yup.object().shape({
		// 	is_employed: yup
		// 		.object()
		// 		.required(t("know-you.employment-validation-msg")),
		// }),
		// nationality_id: yup
		// 	.object()
		// 	.nullable()
		// 	.required(t("know-you.nationality-validation-msg")),// .shape({
		// })
		// birthday: yup.string().required(t("know-you.birthday-validation-msg")),
	});

export {
	loginValidation,
	supportValidation,
	resetPasswordValidation,
	forgetPasswordValidation,
	chnagePasswordValidation,
	knowYouValidation,
};
