import { InitState } from "./initialState";
// import ActionList from "./actions";
import { configureStore, createSlice } from "@reduxjs/toolkit";

const AnswerList = (state, action) => {
		let filterList = [];
		filterList = state.answersList.filter((item) => {
			return item["id"] !== action.payload.payload.id;
		});
		state.answersList = [];
		filterList.map((item) => {
			state.answersList.push(item);
		});
		state.answersList.push(action.payload.payload);
};
const clearAnswerList = (state, action) => {
		state.answersList = action.payload;
};

const CheckedAnswerList = (state, action) =>{
	let filterList = [];
	filterList = state.checkedAnswersList.filter((item) => {
		return item["id"] !== action.payload.payload.id;
	});
	 state.checkedAnswersList = [];
	filterList.map((item) => {
		state.checkedAnswersList.push(item);
	});
	state.checkedAnswersList =[...state.checkedAnswersList , action.payload.payload]
	// state.checkedAnswersList.push(action.payload.payload);
}

const assessmentResult = (state, action) => {
	state.assessmentResult = {
		type: action.payload.type,
		score: action.payload.payload.score,
	};
};

const placementResult = (state, action) => {
	state.placementResult = {
		type: action.payload.type,
		level: action.payload.payload.score.level,
	};
};

const userData = (state, action) => {
	state.user = action.payload;
};
const packageToken = (state, action) => {
	state.token = action.payload;
};

const AppSlice = createSlice({
	name: "engleaseStore",
	initialState: InitState,
	reducers: {
		handleArClass(state, action) {
			state.arClass = action.payload;
		},
		userData,
		assessmentResult,
		placementResult,
		AnswerList,
		CheckedAnswerList,
		packageToken,
		clearAnswerList
	},
});

export const actions = AppSlice.actions;

const store = configureStore({
	reducer: AppSlice.reducer,
});

export default store;
