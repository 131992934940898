import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./index.scss";

const NotFound = () => {
	const { t } = useTranslation();
	const urlParams = new URLSearchParams(location.search);
	const catchedMessage = urlParams.get("catchedMessage");

	return (
		<>
			<div className="thank-you">
				<div className="row typing englease englease__txt--black">
					<p className="m-0"> {t(`page-not-found.title`)} </p>
					<p className="m-0">
						{catchedMessage
							? t(`error-messages.${catchedMessage}`)
							: t(`page-not-found.description`)}
					</p>
				</div>
				<div className="row">
					<Link
						className="btn p-0 border-0 "
						to="/"
						style={{ boxShadow: "none" }}
					>
						<p className="mb-0 py-2 px-4 englease__radius--f-8 englease__bg--main englease__txt--white englease englease--f-18">
							{t("page-not-found.btn")}
						</p>
					</Link>
				</div>
			</div>

			<div className="checkmark"></div>
		</>
	);

	// return (
	// 	<div className="not-found">
	// 		<div className="text-center">
	// 			<h1 className="display-1 text-secondary fw-bold">{t("page-not-found.number")}</h1>
	// 			<p className="fs-3"> <span className="text-danger">{t("page-not-found.warning")}</span> {t("page-not-found.text")}</p>
	// 			<p className="lead">
	// 			{t("page-not-found.description")}
	// 			</p>
	// 			<Link className="btn p-0 border-0 w-75" to="/" style={{boxShadow:'none'}}>
	// 				<p className="mb-0 py-2 px-4 englease__radius--f-8 englease__bg--main englease__txt--white englease englease--f-18">
	// 				{t("page-not-found.btn")}
	// 				</p>
	// 			</Link>
	// 		</div>
	// 	</div>
	// );
};

export default NotFound;
