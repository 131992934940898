import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const About = () => {
  return (
    <section className="about__holder">
      <nav>
        <Link to="/">Home</Link>
      </nav>

      <h2 className="about about__title about__title--active">welcome About</h2>
      <h2 className="about--active">welcome About</h2>
    </section>
  );
};

export default About;
