import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getLevel } from "../../../services/AssessmentService";
import { getUserLevel } from "../../../services/classesService";
import "./styles.scss";
import { actions } from "../../../redux/store";
import { useDispatch } from "react-redux";

const AssessmentResult = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [levelList, setLevelList] = useState([]);
	const studentResult = useSelector((state) => state.assessmentResult);
	const placementResult = useSelector((state) => state.placementResult);
	const [userLevel, setUserLevel] = useState();
	const location = useLocation();
	async function getUserCurrentLevel() {
		const res = await getUserLevel();
		setUserLevel(res.name);
	}
	// Get levels list from service.
	const AssessmentsLvl = async () => {
		const data = await getLevel();
		setLevelList(data.data);
	};
	const handleRetake = () => {
		dispatch(actions.clearAnswerList([]));
		navigate(location.state.page.name, { state: location.state.page.key });
	};
	const handleAvailableClasses = () => {
		dispatch(actions.clearAnswerList([]));
		if (studentResult?.type === "assessment") {
			navigate(`${location.state?.page.key}`);
		} else if (placementResult.type === "placement") {
			navigate("/know-you");
		}
	};
	const testResult = () => {
		let score = "";
		if (studentResult?.type === "assessment") {
			score = Number(studentResult?.score?.percentage);
		} else {
			score = placementResult?.level?.name;
		}
		let homeworkPassed = studentResult?.score?.is_passed;
		window["dataLayer"].push({
			event: homeworkPassed ? "homework_passed" : "homework_failed",
			level: userLevel,
		});
		return score;
	};

	useEffect(() => {
		AssessmentsLvl();
		getUserCurrentLevel();
	}, []);

	return (
		<div className="assessment-result">
			<>
				<h4 className="assessment-result__title">
					{studentResult?.type === "assessment"
						? t("assessment-result.test-result")
						: t("placement-result.level-set")}
				</h4>
				<p>
					{studentResult?.type === "assessment"
						? studentResult?.score?.is_passed === false
							? t("assessment-result.fail.not-completed_successfully")
							: t("assessment-result.success.completed_successfully")
						: t("placement-result.congrats")}
				</p>
				<i
					className={
						studentResult?.type === "assessment"
							? studentResult?.score?.is_passed === false
								? "assessment-result__sad-icon"
								: "assessment-result__celebration-icon"
							: "assessment-result__celebration-icon"
					}
				></i>
				<p>
					{studentResult?.type === "assessment"
						? t("assessment-result.your-score-is")
						: t("placement-result.your-level-is")}
				</p>
				<p
					className={
						studentResult?.type === "assessment"
							? ""
							: "assessment-result__score"
					}
				>
					{testResult()}
				</p>

				<div>
					<p className="">
						{studentResult?.type === "assessment"
							? studentResult?.score?.is_passed === false
								? t("assessment-result.fail.alright-let's-try-again")
								: t("assessment-result.success.congrats")
							: t("placement-result.your-dream-is-one-step-closer")}
					</p>

					{studentResult?.score?.is_passed === false && (
						<button
							className="btn englease__radius--f-8 assessment-result__retakeTest"
							onClick={handleRetake}
						>
							{t("assessment-result.fail.retake-test")}
						</button>
					)}
				</div>
				{studentResult?.type === "assessment" ? (
					<>
						<button
							className="btn mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
							onClick={handleAvailableClasses}
						>
							{t("assessment-result.available-classes")}
						</button>
					</>
				) : (
					<button
						className="btn mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
						onClick={handleAvailableClasses}
					>
						{t("placement-result.view-all-classes")}
					</button>
				)}
			</>
		</div>
	);
};

export default AssessmentResult;
