import Axios from "../Axios";
import { errorHandler } from "../utils";

async function getCertificate(courseData) {
	const params = new URLSearchParams(courseData);

	try {
		const eligible = await Axios.get(`api/certificate/eligibility`, { params });
		return eligible.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}
async function claimingByMail(data, courseData) {
	const params = new URLSearchParams(courseData);
	try {
		const certificateResult = await Axios.post(
			`api/certificate/send-mail?${params}`,
			{
				name: data,
			}
		);
		return certificateResult;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

export { getCertificate, claimingByMail };
