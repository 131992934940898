import React from "react";
import { CircularProgressBar } from "@tomik23/react-circular-progress-bar";

const EngleaseProgressBar = (props) => {
	const { circleChart, currentPercent = 50, size, screen } = props;
	// available control variables
	const circleProps = {
		percent: currentPercent, // is require and will be passed from above
		colorSlice: "#1AB831",
		colorCircle: "#fff",
		fontColor: "#365b74",
		fontSize: "0",
		fontWeight: 400,
		size: size ? size : 44,
		stroke: 16,
		strokeBottom: 16,
		speed: 80,
		cut: 0,
		rotation: -90,
		opacity: 10,
		// fill: "#00897B",
		// unit: "%",
		textPosition: "0.35em",
		animationOff: false,
		// strokeDasharray: "10,1",
		inverse: false,
		round: true,
		number: true,
		// linearGradient: ["#ffff00", "brown"],
	};

	return (
		<div className="d-flex gap-2">
			{screen !== "sm" && <CircularProgressBar {...circleProps} />}
			{Object.keys(circleChart).length !== 0 && (
				<div className={`d-flex flex-column ${size?.contentHolder}`}>
					<div
						className={`d-flex ${size?.head} align-items-center justify-content-center`}
					>
						{/* If customAppearance?.headContent */}
						<p className="mb-0 englease--f-12">{circleChart.headContent}</p>
						{/* If circleChart.icon" */}
						{/* <i className="{{circleChart.icon}} iET-s16 englease__bg--cover "></i> */}
					</div>
					{/* If circleChart.subContent */}
					<p
						className={`${size?.sub} mb-0 englease--f-18 englease--bold text-center`}
					>
						<b>{circleChart.subContent}</b>
					</p>
					{/* If circleChart.statusContent */}
					<p className={`${size?.statusFontSize} mb-0 `}>
						{circleChart.statusContent}
					</p>
				</div>
			)}
		</div>
	);
};

export default EngleaseProgressBar;
