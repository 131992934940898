import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { getCookie } from "../../utils";
import { useTranslation } from "react-i18next";
import { support } from "../../services/support";
import InputField from "../../components/reusable-components/InputField";
import "./index.scss";

const Support = () => {
	const { t } = useTranslation();
	const detectLang = getCookie("i18next");
	const dataObject = require("./data.json");
	const [message, setMessage] = useState(null);
	const [msgStatus, setMsgStatus] = useState(null);
	const [supportList, setSupportList] = useState();

	// Submit Form
	const handleSubmit = async (values) => {
		const supportTeam = await support(values);
		window["dataLayer"].push({ event: "support_form_submitted" });
		if (supportTeam.success === true) {
			setMsgStatus(true);
			setMessage(supportTeam.message);
		} else {
			setMsgStatus(false);
			setMessage(supportTeam.message);
		}
	};
	const handleCloseMsg = () => {
		setMsgStatus(null);
	};

	useEffect(() => {
		setSupportList(dataObject[detectLang]?.reasonList);
	}, []);

	return (
		<div className="support ">
			<div className="row justify-content-center pb-4">
				<div className="mb-4">
					{msgStatus !== null && (
						<div
							className={`support__message--display ${
								msgStatus === true
									? "englease__bg--success--20"
									: "englease__bg--error"
							} position-relative w-100 mt-4 py-3 px-2 englease__radius--f-8`}
						>
							<div className="d-flex">
								<i
									className={`${
										msgStatus === true
											? "iET-Success-Message"
											: "iET-Close-Message"
									} englease__bg--contain englease--click iET-s21 mx-2`}
									onClick={() => handleCloseMsg()}
								></i>
							</div>
							<p className="m-0 englease__txt--black englease englease--f-14 ">
								{message}
							</p>
						</div>
					)}
				</div>
				<div className="support__holder col col-lg-7 px-4 englease__bg--white englease__radius--f-4">
					<div className="w-100 mb-4">
						<p className="m-0 englease englease--f-14">
							{t("support.section-name")}
						</p>
					</div>
					<div className="support__section">
						{/* <!-- Support form --> */}
						<Formik
							initialValues={{ message: "", title: "" }}
							validationSchema={yup.object({
								title: yup
									.string()
									.min(5, `* ${t("support.support-title-validation")}`)
									.required(`* ${t("support.support-required-title")}`),
								message: yup
									.string()
									.min(10, `* ${t("support.support-validation-msg")}`)
									.required(`* ${t("support.support-required-msg")}`),
							})}
							onSubmit={handleSubmit}
						>
							{({ values, isValid }) => (
								<Form className="w-100 px-sm-5 mx-sm-5 d-grid gap-4">
									{Array.isArray(supportList) && (
										<>
											{/* title */}
											<InputField
												fieldtype={"select"}
												label={t("support.title")}
												name="title"
												id="label-title"
												list={supportList}
												className="englease__txt--black englease englease--f-16 p-2 englease__border--muteTxt englease__radius--f-8 englease__bg--light"
											>
												<option value="">{t("support.option")}</option>
											</InputField>

											{/* message */}
											<InputField
												fieldtype={"text-area"}
												label={t("support.message")}
												placeholder={t("support.message")}
												name="message"
												type="text"
												rows="6"
												id="message"
												className="englease__txt--black englease englease--f-16 p-2 englease__border--muteTxt englease__radius--f-8 englease__bg--light"
											/>

											<button
												className={`mt-4 englease__bg--main englease englease--f-16 englease__txt--white support__btn ${
													!isValid ? "englease--unClick" : ""
												}`}
												type="submit"
												disabled={!isValid}
											>
												{t("support.btn")}
											</button>
										</>
									)}
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Support;
