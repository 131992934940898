import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { ThemeContext } from "../../App";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { actions } from "../../redux/store";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { loginUser } from "../../services/studentService";
import { saveLanguageToCookie, getCookie } from "../../utils";
import { loginValidation } from "../../schema/schemaValidation";
import "./styles.scss";

const Login = (props) => {
	const { setToken } = props;
	const theme = useContext(ThemeContext);
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const detectLang = getCookie("i18next");
	const [message, setMessage] = useState(null);
	const [msgStatus, setMsgStatus] = useState(null);
	const [toggleShowPassword, setToggleShowPassword] = useState(false);

	// TODO Listen Current Languauge To Switch Icon And View Direction
	const handleLang = useCallback((lng) => {
		i18n.changeLanguage(lng);
		if (lng === "ar") dispatch(actions.handleArClass(true));
		else dispatch(actions.handleArClass(false));
		saveLanguageToCookie(lng);
	}, []);

	// Submit Form
	const handleSubmit = async (values, actions) => {
		setMsgStatus(null);
		localStorage.removeItem("isEb");
		localStorage.removeItem("name");
		localStorage.removeItem("email");
		localStorage.removeItem("level");
		localStorage.removeItem("token");
		localStorage.removeItem("userInfo");
		localStorage.removeItem("pre_order_id");
		const userLogin = await loginUser(values);
		// Get User Data And Set It In LocalStorage
		//for tracking user logging
		window["dataLayer"].push({ event: "Login", value: userLogin.user });

		if (userLogin == "Network Error") {
			setMsgStatus(false);
			setMessage(userLogin);
		}
		if (userLogin?.success !== false) {
			const userInfo = {
				id: userLogin?.user?.user_id,
				leadId: userLogin?.user?.lead_id,
				name: userLogin?.user?.first_name,
				subscriptions: userLogin?.user?.subscriptions,
				prerecordedLevelId: userLogin?.user?.prerecorded_level_id || "",
			};
			if (userLogin?.redirect) location.assign(userLogin?.redirect);
			else {
				localStorage.setItem("name", userLogin?.user?.name);
				localStorage.setItem("email", userLogin?.user?.email);
				localStorage.setItem("userInfo", JSON.stringify(userInfo));
				localStorage.setItem(
					"enterpriseObj",
					JSON.stringify({
						...userLogin?.user?.enterprise,
						remaining_subscription_days:
							userLogin?.user?.remaining_subscription_days || null,
					})
				);
				localStorage.setItem("pre_order_id", userLogin?.user?.preorder_id);
				localStorage.setItem("level", userLogin?.user?.level?.abbreviation);
				if (userLogin?.user?.enterprise?.enterprise_name)
					localStorage.setItem(
						"corp-name",
						userLogin?.user?.enterprise?.enterprise_name
					);
				setToken(userLogin?.tokenDetails?.access_token);
				if (
					userLogin?.user?.level === null &&
					userLogin?.user?.enterprise?.student_status !== 2
				) {
					location.assign("/placement-test");
				} else {
					// console.log(userLogin);
					location.assign("/");
				}
			}
		} else {
			setMsgStatus(false);
			setMessage(userLogin?.message);
		}
	};
	const handleCloseMsg = () => {
		setMsgStatus(null);
	};
	const labelStyle = {
		"& .MuiInputLabel-root": {
			fontSize: "16px",
			color: "#000",
			backgroundColor: "#fff",
			padding: "0px 5px 0px 0px",
		},
	};

	return (
		<section className="login">
			{/* Login Holder For All [Section] */}
			<div className="login__image"></div>
			{/* Login Form [Holder] */}
			<div className="login__form-wrapper col col-lg-4">
				{/* <!-- logo --> */}
				<div className="login__form-logo w-100">
					<picture>
						<img
							src={`${
								theme?.logo ? theme?.logo : "/assets/images/logooc.webp"
							}`}
							alt="logooc"
							loading="lazy"
							width="248"
							height="51"
						/>
					</picture>
				</div>
				{/* <!-- title --> */}
				<div className="login__form-title englease--bold englease--f-24">
					{t("login.login-to-your-account")}
				</div>
				{/* <!-- login form --> */}
				<Formik
					initialValues={{ email: "", password: "" }}
					validationSchema={loginValidation}
					onSubmit={handleSubmit}
				>
					{({ values, errors, touched, handleChange }) => (
						<Form className="login__form-holder" autoComplete="off">
							{/* // Email Input */}
							<div>
								<TextField
									sx={labelStyle}
									fullWidth
									id="email"
									name="email"
									size="small"
									label={t("login.email")}
									onChange={handleChange}
									inputProps={{ className: "englease__txt--black englease" }}
									InputLabelProps={{
										className:
											"englease englease__txt--black englease--f-14 login__label ",
									}}
									value={values.email}
									error={Boolean(touched.email && errors.email)}
								/>
							</div>
							<div className="login--error englease--f-12">
								{touched.email && errors.email
									? `${touched.email && errors.email}`
									: ""}
							</div>
							{/* // Password Input */}
							<div className="mt-4 position-relative">
								<TextField
									fullWidth
									id="password"
									name="password"
									label={t("login.password")}
									size="small"
									type={toggleShowPassword ? "text" : "password"}
									value={values.password}
									onChange={handleChange}
									inputProps={{ className: "englease__txt--black englease" }}
									InputLabelProps={{
										className:
											"englease englease__txt--black englease--f-14  login__label",
									}}
									error={Boolean(touched.password && errors.password)}
								/>
								{/* Show And Hide New Password  [Icon]  */}
								<i
									className={`${
										toggleShowPassword ? "iET-Eyes-hide" : "iET-Eyes-show"
									}
                     iET-s16 englease__bg--contain englease--click login__password-icon`}
									onClick={() => setToggleShowPassword(!toggleShowPassword)}
								></i>
							</div>
							<div className="login--error englease--f-12">
								{touched.password && errors.password
									? `${touched.password && errors.password}`
									: ""}
							</div>
							{/* // Forget Password  */}
							<div className="login--forgetPassword">
								<NavLink
									className="englease__txt--main englease--bold englease--f-14"
									to="/forget-password"
								>
									{t("login.forget-password")}
								</NavLink>
							</div>
							{msgStatus !== null && (
								<div
									className={`login__message--display ${
										msgStatus === true
											? "englease__bg--success--20"
											: "englease__bg--error"
									} position-relative w-100 mt-4 py-3 px-2 englease__radius--f-8`}
								>
									<i
										onClick={() => handleCloseMsg()}
										className={` ${
											msgStatus === true
												? "iET-Success-Message"
												: "iET-Close-Message"
										}
               englease--click englease__bg--contain iET-s21 mx-2`}
									></i>
									<p className="m-0 englease__txt--black englease englease--f-14 text-start ">
										{message}
									</p>
								</div>
							)}
							{/* -- Lang Switch [Icon - Text] */}
							<div className="d-flex justify-content-center px-4 login--language">
								{detectLang == "en" ? (
									<div
										className={` ${
											detectLang == "en" && "englease--bold"
										} d-flex gap-2 justify-content-center align-items-center englease--f-18  englease--click`}
										onClick={() => handleLang("ar")}
									>
										<span> عربى </span>
										{/* <i className="iET-Global englease__bg--contain iET-s21 "></i> */}
										<img
											src="/assets/icons/Global.svg"
											className="englease__bg--contain iET-s21"
											alt="englease-Global"
											width="21"
											height="21"
										/>
									</div>
								) : (
									<div
										className={` ${
											detectLang == "ar" && "englease--bold"
										} d-flex gap-2 justify-content-center align-items-center englease--f-20  englease--click`}
										onClick={() => handleLang("en")}
									>
										<span> EN </span>
										<img
											src="/assets/icons/Global.svg"
											className="englease__bg--contain iET-s21"
											alt="englease-Global"
											width="21"
											height="21"
										/>
									</div>
								)}
							</div>
							<button
								type="submit"
								className="englease__bg--main englease englease--f-16 englease__txt--white login__btn"
							>
								{t("login.login-btn")}
							</button>
						</Form>
					)}
				</Formik>
				{/* // <!-- sign up --> */}
				{process.env.REACT_APP_ENV !== "production" && (
					<div className="englease--f-14 login--signup">
						{t("login.have-account")}
						<NavLink
							className="englease__txt--main px-sm-2 englease--bold px-1"
							to="/register"
						>
							{t("login.sign-up")}
						</NavLink>
					</div>
				)}
			</div>
		</section>
	);
};
Login.propTypes = {
	setToken: PropTypes.func.isRequired,
};
export default Login;
