import axios from "axios";
import { getCookie } from "../utils";

const Axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	mode: "cors",
	// timeout: 100
});

// Add a request interceptor
Axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		let token = localStorage.getItem("token");
		config.headers = {
			...config.headers,
			Authorization: token ? `Bearer ${JSON.parse(token)}` : "",
			"Access-Control-Allow-Headers":
				"Origin, X-Requested-With, Content-Type, Accept",
			"Access-Control-Allow-Origin": "*",
			"content-Type": "application/json",
			Accept: "application/json",
			Language: getCookie("i18next"),
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		};
		return config;
	},
	function (error) {
		// Do something with request error
		error.toJSON();
		return Promise.reject(error);
	}
);

// Add a response interceptor
Axios.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		// response;
		return !response.data.success ? response : response;
	},
	(error) => {
		try {
			// if (error.response.status >= 400) {
			// 	if (error.response.status === 401) {
			// 		localStorage.clear();
			// 		location.assign("/");
			// 	} else if (error.response.status === 400) {
			// 		console.error(error.response?.data);
			// 	} else {
			// 		console.error(error);
			// 	}
			// }
			if (error.response.status >= 400) {
				if (error.response.status === 401) {
					if (process.env.REACT_APP_ENV === "production") {
						localStorage.clear();
						location.assign("/");
					} else {
						console.log(error);
					}
				}
			}
		} catch {
			if (process.env.REACT_APP_ENV !== "production") console.log(error);
			console.log("Server Is Down");
			// localStorage.clear();
			// location.assign("/");
		}
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		if (process.env.REACT_APP_ENV === "production")
			return Promise.reject(error);
	}
);

export default Axios;
