export default function SurveyMapper(item) {
	const survey = {
		...item,
		answers: item?.answers?.map((answer) => ({
			...answer,
			value: +answer?.value * 10,
			label: answer?.value,
			name: answer?.id,
		})),
	};

	return survey;
}
