import Axios from "../Axios";
import { errorHandler } from "../utils";
import SurveyMapper from "../mappers/Survey";

async function getSurvey() {
	try {
		const getStudentSurvey = await Axios.get(
			`api/classes/reflection/status?web=true`
		);
		return getStudentSurvey.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}
async function getSurveyQuestions(surveyType = "") {
	try {
		const getSurveyQuestions = await Axios.get(
			`api/classes/reflection/questions?reflection=${surveyType}`
		);
		return getSurveyQuestions?.data?.data?.map((item) => SurveyMapper(item));
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}
async function submitSurveyAnswer(data, surveyStatus) {
	try {
		const submitSurveyAnswer = await Axios.post(
			`api/classes/reflection/answers?${surveyStatus}`,
			data
		);
		return submitSurveyAnswer.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.message;
	}
}

async function submitSelfSurvey(data) {
	try {
		const submitSelfSurveyAnswer = await Axios.post(
			`api/self-assessment`,
			data
		);
		return submitSelfSurveyAnswer.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.message;
	}
}

export { getSurvey, getSurveyQuestions, submitSurveyAnswer, submitSelfSurvey };
