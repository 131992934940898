import Axios from "../Axios";
import { errorHandler } from "../utils";
import ServiceHandler from "../utils/ServiceHandler";

// a service created for getting placement data from db.
async function getPlacementQuestions(testId) {
	try {
		const data = await Axios.get(`api/placement-test/${testId}`);
		return data.data.data;
	} catch (e) {
		errorHandler(e.response.status);
		// console.log(e.message);
	}
}

// a service created for setting placement data from db.
async function postPlacementAnswers(answers) {
	let answersPosted = { answers: answers };
	try {
		const result = await Axios.post(
			`api/placement-test-result/`,
			answersPosted
		);
		return result.data;
	} catch (e) {
		errorHandler(e.response.status);
		// console.log(e.message);
	}
}

// a service created for getting assessment data from db.
async function getAssessmentQuestions(testId) {
	return await ServiceHandler("get", `api/assessment/${testId}`, null, null);
}

// a service created for setting assessment data from db.
async function postAssessmentAnswers(answers) {
	let answersPosted = { answers: answers };
	try {
		const result = await Axios.post(`api/assessment-answers/`, answersPosted);
		return result.data;
	} catch (e) {
		errorHandler(e.response.status);
		// console.log(e.message);
	}
}

// a service created for getting levels data from db.
async function getLevel() {
	try {
		const getLvl = await Axios.get(`api/levels`);
		return getLvl.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

// a service created for getting levels data from db.
async function getResults(id) {
	try {
		const getResult = await Axios.get(`api/assessment-result-new/${id}`);
		return getResult.data.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

export {
	getPlacementQuestions,
	postPlacementAnswers,
	getAssessmentQuestions,
	postAssessmentAnswers,
	getLevel,
	getResults,
};
