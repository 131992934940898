import Axios from "../Axios";
import axios from "axios";

import { StudentMapper } from "../mappers/Students";
import ServiceHandler from "../utils/ServiceHandler";
import { errorHandler } from "../utils";

async function loginUser(data) {
	try {
		const user = await Axios.post(`api/auth/login`, data);
		if (user.data.success === true) {
			const token = JSON.stringify(user.data.data.tokenDetails.access_token);
			if (!token) {
				return;
			}
			return user.data.data;
		} else {
			return user.data;
		}
	} catch (e) {
		// errorHandler(e.response.status);
		return e.message;
	}
}

async function register(data) {
	try {
		const registeredUser = await Axios.post(`api/auth/register`, data);
		const token = JSON.stringify(
			registeredUser.data.data.tokenDetails.access_token
		);
		if (!token) {
			return;
		}
		return registeredUser.data.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.response.data;
	}
}
async function forgetPasswordUser(user) {
	try {
		const forgetPasswordUser = await Axios.post(
			`api/auth/reset-password-request`,
			user
		);
		return forgetPasswordUser.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.response.data;
	}
}
async function resetPasswordUser(user) {
	try {
		const res = await Axios.post(`api/auth/reset-password`, user);

		const token = JSON.stringify(res.data.data.tokenDetails.access_token);
		if (!token) {
			return;
		}
		return res.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.message;
	}
}

// async function getKnowYouData() {
// 	return await ServiceHandler("get", `api/auth/profile`, null, StudentMapper);
// }

async function postKnowYouData(data) {
	try {
		const postProfileData = await Axios.post(`api/auth/user/info`, data);
		return postProfileData.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.response.data;
	}
}

async function getProfileData() {
	return await ServiceHandler("get", `api/auth/profile`, null, StudentMapper);
}

async function postProfileData(data) {
	try {
		const postProfileData = await Axios.post(`api/auth/update-profile`, data);
		return postProfileData.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.response.data;
	}
}

async function timezone() {
	try {
		const timezone = await axios.get(`https://worldtimeapi.org/api/timezone`);
		return timezone?.data || null;
	} catch (e) {
		return null;
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

async function changePassword(password) {
	try {
		const changePasswordUser = await Axios.post(
			`api/auth/update-password`,
			password
		);
		return changePasswordUser.data;
	} catch (e) {
		// errorHandler(e.response.status);
		return e.response.data;
	}
}

async function countries() {
	try {
		const countries = await axios.get(
			`https://countriesnow.space/api/v0.1/countries/population`
		);
		return countries?.data?.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

async function userPlan() {
	try {
		const userPlan = await Axios.get(`api/plan/getUserPlans`);
		return userPlan?.data?.data;
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}

const postStudentHctForm = async (data) => {
	try {
		const postStudentHctForm = await Axios.post(`api/user/learning-time`, data);
		return postStudentHctForm.data;
	} catch (e) {
		return e.response.data;
	}
};

export {
	loginUser,
	register,
	forgetPasswordUser,
	resetPasswordUser,
	// getKnowYouData,
	postKnowYouData,
	getProfileData,
	postProfileData,
	timezone,
	countries,
	changePassword,
	userPlan,
	postStudentHctForm,
};
