import React, { lazy, useContext } from "react";
import { ThemeContext } from "../../App";
import useToken from "../../helpers/useToken";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";
import Login from "../../pages/Login";
import About from "../../pages/About";
import HCTForm from "../../pages/HCTForm";
import Support from "../../pages/Support";
import Renewal from "../../pages/Renewal";
import NotFound from "../../pages/NotFound";
import Survey from "../../components/Survey";
import Dashboard from "../../pages/Dashboard";
import ResetPassword from "../../pages/ResetPassword";
import PracticeVideos from "../../pages/PracticeVideos";
import QuestionsAnswer from "../../pages/TakeAssessmentTest/QuestionsAnswer";
import AssessmentResult from "../../pages/TakeAssessmentTest/AssessmentResult";

const KnowYou = lazy(() => import("../../pages/KnowYou"));
const Register = lazy(() => import("../../pages/Register"));
const AttendZoom = lazy(() => import("../../pages/AttendZoom"));
const ClassesList = lazy(() => import("../../pages/ClassesList"));
const PreRecorded = lazy(() => import("../../pages/PreRecorded"));
const WalkThrough = lazy(() => import("../../pages/WalkThrough"));
const ForgetPassword = lazy(() => import("../../pages/ForgetPassword"));
const StudentProfile = lazy(() => import("../../pages/StudentProfile"));
const RenewalPayment = lazy(() => import("../../pages/RenewalPayment"));
const ChargebeePayment = lazy(() =>
	import("../../pages/RenewalPayment/ChargebeePayment")
);
const TakePlacementTest = lazy(() => import("../../pages/TakeAssessmentTest"));
const PlacementTest = lazy(() =>
	import("../../pages/TakeAssessmentTest/PlacementTest")
);
const AssessmentTest = lazy(() =>
	import("../../pages/TakeAssessmentTest/AssessmentTest")
);

import "./styles.scss";

const MasterContainer = () => {
	const location = useLocation();
	const theme = useContext(ThemeContext);
	const { token, setToken } = useToken("");

	const routes = [
		{ path: "", element: <ClassesList /> },
		{ path: "about", element: <About /> },
		{ path: "survey", element: <Survey /> },
		{ path: "know-you", element: <KnowYou /> },
		{ path: "walk-through", element: <WalkThrough /> },
		{ path: "subscription-renewal", element: <Renewal /> },
		{ path: "practice-videos", element: <PracticeVideos /> },
		{ path: "attend-test/:id", element: <AssessmentTest /> },
		{ path: "student-profile", element: <StudentProfile /> },
		{ path: "join-class/:classId", element: <AttendZoom /> },
		{ path: "placement-test", element: <TakePlacementTest /> },
		{ path: "assessment-result", element: <AssessmentResult /> },
		{ path: "attend-placement-test", element: <PlacementTest /> },
		{ path: "subscription-payment", element: <RenewalPayment /> },
		{ path: "chargebee-payment", element: <ChargebeePayment /> },
		{ path: "questionsAnswer/:name/:id", element: <QuestionsAnswer /> },
		{ path: "pre-recorded", element: <PreRecorded /> },
		{ path: "support", element: <Support /> },
		{ path: "*", element: <NotFound /> },
	];

	// Tracking token existance if not it will render the login page

	return (
		<>
			{/* TODO Remmber that Set the NOT Operator */}
			{!token ? (
				<Routes>
					{process.env.REACT_APP_ENV !== "production" && (
						<Route path="register" element={<Register setToken={setToken} />} />
					)}
					{/* <Route path="hct-slots" element={<HCTForm />} /> */}
					<Route path="forget-password" element={<ForgetPassword />} />
					<Route
						path="reset-password"
						element={<ResetPassword setToken={setToken} />}
					/>
					<Route path="*" element={<Login setToken={setToken} />} />
				</Routes>
			) : (
				<>
					<header className="">
						<Header setToken={setToken} />
					</header>
					<section
						className={`master-container container-fluid ${
							location.pathname === "/practice-videos" ? "p-0" : "py-4"
						}`}
					>
						{/* Render Here All Pages */}
						<Routes>
							{routes.map((el, ix) => {
								return <Route key={ix} path={el.path} element={el.element} />;
							})}
						</Routes>
					</section>
					<footer className="p-3 text-black text-center">
						<Footer />
					</footer>
				</>
			)}
		</>
	);
};

export default MasterContainer;
