import React, {
	Suspense,
	useLayoutEffect,
	useEffect,
	createContext,
	useState,
} from "react";
import { actions } from "./redux/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// import { MantineProvider } from "@mantine/core";

import MasterContainer from "./layout/MasterContainer";
import { getEnterpriseDetails } from "./services/b2bServices";

import "./App.scss";

export const ThemeContext = createContext({});

const App = () => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const [theme, setTheme] = useState({});
	const localLang = localStorage.getItem("i18nextLng", "ar");
	const urlParams = new URLSearchParams(location.search);

	useLayoutEffect(() => {
		const enterpriseName = urlParams.get("enterprise");
		if (enterpriseName) localStorage.setItem("corp-name", enterpriseName);
	}, []);

	// TODO Get Survey Questions From APIs Response
	const enterpriseData = async () => {
		const talaaqaTheme = {
			logo: "/assets/images/Talaaqa/Talaaqa-logo.png",
			primary_color: "#87189D",
			certificate_color: "#FF6A13",
			header: {
				primary_color: "#fff", //#dca523
				background_color: "#87189D", //"#4e2b80"
				text_color: "#cab6db",
			},
		};

		if (localStorage.getItem("corp-name") === "Talaaqa") {
			setTheme(talaaqaTheme);
			localStorage.setItem("enterprise-theme", JSON.stringify(talaaqaTheme));
		} else {
			const response = await getEnterpriseDetails();
			setTheme(response);
			response &&
				localStorage.setItem("enterprise-theme", JSON.stringify(response));
		}
	};

	useEffect(() => {
		const enterpriseName = urlParams.get("enterprise");

		if (
			localStorage.getItem("enterprise-theme") == undefined ||
			(enterpriseName != "" &&
				enterpriseName != null &&
				enterpriseName != undefined)
		) {
			enterpriseData();
		}
	}, []);

	useEffect(() => {
		i18n?.changeLanguage(localLang);
		switch (localLang) {
			case "ar":
				dispatch(actions.handleArClass(true));
				break;
			default:
				dispatch(actions.handleArClass(false));
				break;
		}
	}, [localLang]);

	useEffect(() => {
		const enterpriseTheme = JSON.parse(
			localStorage.getItem("enterprise-theme")
		);
		if (
			(enterpriseTheme !== null && enterpriseTheme !== undefined) ||
			(theme !== undefined && theme !== null && Object.keys(theme).length !== 0)
		) {
			document.documentElement.style.setProperty(
				"--color-main",
				`${
					enterpriseTheme
						? enterpriseTheme?.primary_color
						: theme?.primary_color
				}`
			);
			document.documentElement.style.setProperty(
				"--color-Lavender",
				`${
					enterpriseTheme
						? enterpriseTheme?.primary_color
						: theme?.primary_color
				}`
			);
			document.documentElement.style.setProperty(
				"--color-nav",
				`${
					enterpriseTheme
						? enterpriseTheme?.header?.text_color
						: theme?.header?.text_color
				}`
			);
			document.documentElement.style.setProperty(
				"--nav-main",
				`${
					enterpriseTheme?.header?.primary_color
						? enterpriseTheme
							? enterpriseTheme?.header?.primary_color
							: theme?.header?.primary_color
						: enterpriseTheme?.primary_color
				}`
			);
		}
	}, [theme, localStorage.getItem("enterprise-theme")]);

	function loader() {
		let enterpriseTheme = localStorage.getItem("enterprise-theme");
		enterpriseTheme !== undefined && enterpriseTheme !== null
			? (enterpriseTheme = JSON.parse(enterpriseTheme))
			: (enterpriseTheme = null);
		return (
			// to be a reusable component for multiple usage
			<div className="text-center justify-content-center align-middle">
				<div className="align-items-center flex-nowrap spinner">
					<img
						src={
							enterpriseTheme == null || enterpriseTheme == undefined
								? "/assets/images/logooc.webp"
								: `${enterpriseTheme?.logo}`
						}
						width="300"
						height="80"
						alt="englease-logo"
					/>
				</div>
			</div>
		);
	}

	return (
		// <MantineProvider withGlobalStyles withNormalizeCSS>
		<ThemeContext.Provider
			value={JSON.parse(localStorage.getItem("enterprise-theme"))}
		>
			<Suspense fallback={loader()}>
				<section
					className={`app ${localLang == "ar" ? "englease-rtl" : "englease"}`}
				>
					<main className="content-wrapper">
						<MasterContainer />
					</main>
				</section>
			</Suspense>
		</ThemeContext.Provider>
		// </MantineProvider>
	);
};

export default App;
