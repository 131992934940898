import React, { useEffect, useCallback, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../App";
import { saveLanguageToCookie } from "../../utils";
import { getProfileData } from "../../services/studentService";
// import { subscriptionsMapper } from "../../mappers/Students";
import "./styles.scss";

const lngs = {
	en: { nativeName: "EN" },
	ar: { nativeName: "AR" },
};

const Header = () => {
	const theme = useContext(ThemeContext);
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const [isDropdownOpend, setDropdownOpen] = useState(false);
	const isLargeScreen = useMediaQuery({ query: `(max-width: 1200px)` });
	const isTabletOrMobile = window.screen.width;
	let enterpriseName = localStorage.getItem("corp-name");

	const talaaqaCommitmentNavigation = (profile) => {
		if (profile?.enterpriseObj?.enterprise_name) {
			let { enterprise_name, is_commitment_signed, university } =
				profile?.enterpriseObj;
			if (
				enterprise_name == "Talaaqa" &&
				is_commitment_signed == false &&
				profile?.levelAbbreviation !== null
			) {
				location.replace(
					`${
						process.env.REACT_APP_REDIRECTION_LINK
					}/student-commitment?token=${JSON.parse(
						localStorage.getItem("token")
					)}&university=${university}`
					// .replaceAll('"', "")}`
				);
			}
		}
	};

	const getUserSubscription = async () => {
		// if (!Array.isArray(getData?.subscriptions)) {
		// 	localStorage.clear();
		// } else {
		const profile = await getProfileData();
		const userInfo = {
			name: profile?.firstName,
			id: profile?.id,
			leadId: profile?.leadId,
			prerecordedLevelId: profile?.preRecordedLevelId,
			subscriptions: profile?.subscriptions,
		};
		localStorage.setItem("isEb", JSON.stringify(profile?.isEb));
		localStorage.setItem("name", profile?.name);
		localStorage.setItem("email", profile?.email);
		localStorage.setItem("level", profile?.levelAbbreviation);
		localStorage.setItem("pre_order_id", profile?.preOrderId);
		localStorage.setItem("userInfo", JSON.stringify(userInfo));
		localStorage.setItem(
			"enterpriseObj",
			JSON.stringify(profile?.enterpriseObj)
		);
		// }
		talaaqaCommitmentNavigation(profile);
	};
	useEffect(() => {
		getUserSubscription();
	}, []);

	let userLevel = useSelector((state) => state.user?.levelAbbreviation);
	let detectUserLevel = localStorage.getItem("level");

	const subscriptionArr = JSON.parse(localStorage.getItem("userInfo"));
	// const userSubscriptions = subscriptionsMapper(subscriptionArr?.subscriptions);

	const SendToken = (type, status) => {
		window["dataLayer"].push({
			event: "renew_clicked",
			parameter_screen: "header",
		});
		navigate(`/${type === "courses" ? "" : type}`);
	};
	let coursesTabs = 0;
	const handleRoute = (el, idx, type) => {
		let classType, orderRoute;

		switch (el?.lessonTypeId) {
			case 30:
				classType = "pre-recorded";
				orderRoute = 2;
				break;
			default:
				if (coursesTabs === 0) {
					classType = "courses";
					orderRoute = 1;
					coursesTabs++;
				}
		}
		if (type === "LargeScreen") {
			if (classType === undefined) {
				return;
			} else {
				return (
					<NavLink
						to={classType === "courses" ? "/" : classType}
						key={idx}
						className={({ isActive }) =>
							isActive ? `englease__txt--nav-main` : "englease__txt--color-nav"
						}
						style={{ order: orderRoute }}
						onClick={() => SendToken(classType, el.isExpired)}
					>
						{t(`${classType}`)}
					</NavLink>
				);
			}
		} else {
			if (classType === undefined) {
				return;
			} else {
				return (
					<Dropdown.Item
						style={{
							color: theme?.header?.text_color
								? theme?.primary_color
								: "var(--color-black)",
						}}
						// ? // ? theme?.header?.text_color
						key={idx}
						active={
							location.pathname ===
							`/${classType === "courses" ? "" : classType}`
								? true
								: false
						}
						onClick={() => SendToken(classType, el.isExpired)}
					>
						<span>{t(`${classType}`)}</span>
					</Dropdown.Item>
				);
			}
		}
	};

	const handleLang = useCallback((lng) => {
		saveLanguageToCookie(lng);
		localStorage.setItem("i18nextLng", lng);
		location?.reload();
	}, []);

	const handleLogOut = () => {
		localStorage.clear();
		location.assign("/");
	};

	const handleClose = (e) => {
		setDropdownOpen(e);
	};
	const mobileScreenLinks = [
		{
			id: 1,
			link: "/practice-videos",
			name: t("practice-videos"),
			isNav: NavLink,
			isActive: "",
			hasGroup: true,
		},
		{
			id: 2,
			link: "/walk-through",
			name: t("walk-through"),
			isNav: NavLink,
			isActive: "",
			hasGroup: true,
		},
		{
			id: 3,
			link: "/support",
			name: t("nav-support"),
			isNav: NavLink,
			isActive: "",
			hasGroup: true,
		},
		{
			id: 4,
			link: "/student-profile",
			name: t("profile"),
			isNav: NavLink,
			isActive: "",
			hasGroup: true,
		},
		{
			id: 5,
			name: t("logout"),
			methodFn: handleLogOut,
			isActive: "",
			hasGroup: true,
		},
	];
	const SmallNavLink = () => {
		return mobileScreenLinks.map((nav, idx) => {
			return (
				nav.isActive !== null &&
				nav.hasGroup === true && (
					<Dropdown.Item
						key={nav.id}
						as={nav.isNav}
						to={nav.link}
						onClick={nav.methodFn}
					>
						<span>{nav.name}</span>
					</Dropdown.Item>
				)
			);
		});
	};
	return (
		<section
			className="container-fluid py-4 border-bottom border-1 border-light"
			style={{ backgroundColor: theme?.header?.background_color }}
		>
			<div className="row align-items-center flex-nowrap gap-4">
				{/* Logo Image  */}
				<figure className="col d-flex align-items-center flex-nowrap mb-0">
					<picture>
						<img
							src={`${
								theme?.logo ? theme?.logo : "/assets/images/Engleaselogo.jpg"
							}`}
							className="englease__bg--contain "
							alt="englease-logo"
							width={`${theme?.logo ? (isLargeScreen ? 124 : 176) : 117}`}
							height={`${theme?.logo ? (isLargeScreen ? 42 : 63) : 22}`}
						/>
					</picture>
				</figure>

				{/* Navigators  */}
				{!isLargeScreen && (
					<nav className="col flex-fill d-none d-lg-flex gap-5">
						{detectUserLevel != "null" &&
							detectUserLevel != "undefined" &&
							subscriptionArr?.subscriptions?.map((el, idx) => {
								return handleRoute(el, idx, "LargeScreen");
							})}

						<NavLink
							className={({ isActive }) =>
								isActive
									? "englease__txt--nav-main"
									: "englease__txt--color-nav"
							}
							style={{ order: "10" }}
							to="/practice-videos"
						>
							{t("practice-videos")}
						</NavLink>
						<NavLink
							className={({ isActive }) =>
								isActive
									? "englease__txt--nav-main"
									: "englease__txt--color-nav"
							}
							style={{ order: "10" }}
							to="/walk-through"
						>
							{t("walk-through")}
						</NavLink>
					</nav>
				)}
				<section
					className={`col d-none d-lg-${
						enterpriseName === "Talaaqa"
							? "inline text-center align-items-center"
							: "flex"
					}`}
				>
					{/* Icons Section */}

					{enterpriseName === "Talaaqa" && (
						<figure className="col-12 d-block mb-2 flex-nowrap mb-0">
							<picture>
								<img
									src="/assets/images/Talaaqa/in-partnership-with-englease.png"
									className="englease__bg--contain "
									alt="englease-partnership-logo"
									width="163"
									height="52"
								/>
							</picture>
						</figure>
					)}

					<div className="col-12 d-flex gap-4 align-items-center justify-content-end">
						<Dropdown>
							<Dropdown.Toggle
								aria-label="dorpMenu-lang"
								variant=""
								id="language-dropdown"
								className="p-0 d-flex"
							>
								<img
									src={`/assets/icons/icons for dashboard svg-globe ${
										enterpriseName === "Talaaqa" ? "white" : "black"
									}.svg`}
									className="englease__bg--contain uET-click "
									alt="englease-Global"
									width="21"
									height="21"
								/>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{Object.keys(lngs).map((lng, idx) => (
									<Dropdown.Item
										className={
											isTabletOrMobile < "768px"
												? `dropdown-menu-lang iET-Global${
														enterpriseName === "Talaaqa" ? "-light" : ""
												  } englease__bg--contain`
												: ""
										}
										style={{
											fontWeight:
												i18n.resolvedLanguage === lng ? "bold" : "normal",
											color: i18n.resolvedLanguage === lng ? "#244d95" : "",
										}}
										key={idx}
										onClick={() => handleLang(lng)}
									>
										{lngs[lng].nativeName}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
						<div className="uET-horizental-divider"></div>
						{/* User Icon  */}
						<NavLink to="/support" aria-label="support">
							<img
								src={`/assets/icons/icons for dashboard svg-question mark ${
									enterpriseName === "Talaaqa" ? "white" : "black"
								}.svg`}
								className="englease__bg--contain uET-click"
								alt="englease-support"
								width="21"
								height="21"
							/>
						</NavLink>
						<div className="uET-horizental-divider"></div>
						<NavLink
							aria-label="profile"
							to="/student-profile"
							className={`iET-user-${
								enterpriseName === "Talaaqa" ? "light" : "dark"
							} englease__bg--contain  iET-s21 uET-click`}
						></NavLink>
						<div className="uET-horizental-divider d-lg-none"></div>
					</div>
				</section>

				<Dropdown className="col gap-4 justify-content-end align-items-center d-flex d-lg-none">
					<Dropdown>
						<Dropdown.Toggle
							variant=""
							id="lang-dropdown"
							className="p-0 d-flex"
							name="language"
							aria-label="language"
						>
							<i
								className={`iET-Global${
									enterpriseName === "Talaaqa" ? "-light" : ""
								} englease__bg--contain  iET-s21 uET-click`}
							></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{Object.keys(lngs).map((lng, idx) => (
								<Dropdown.Item
									className={
										isTabletOrMobile < "768px"
											? `dropdown-menu-lang iET-Global${
													enterpriseName === "Talaaqa" ? "-light" : ""
											  } englease__bg--contain`
											: ""
									}
									style={{
										fontWeight:
											i18n.resolvedLanguage === lng ? "bold" : "normal",
										color: i18n.resolvedLanguage === lng ? "#244d95" : "",
									}}
									key={idx}
									onClick={() => handleLang(lng)}
								>
									{lngs[lng].nativeName}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown autoClose={true} onToggle={(e) => handleClose(e)}>
						<Dropdown.Toggle
							variant=""
							id="dropdown-basic"
							className="d-flex p-0 align-items-center"
							name="Menublack"
							aria-label="Menublack"
						>
							<div className="menuToggle">
								<input
									className="menuToggle__input-holder"
									onChange={() => setDropdownOpen(!isDropdownOpend)}
									id="menu-burger"
									aria-labelledby="dropdown-basic"
									type="checkbox"
									checked={isDropdownOpend}
								/>
								<p className="span1"></p>
								<p className="span2"></p>
								<p className="span3"></p>
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{detectUserLevel != "null" &&
								detectUserLevel != "undefined" &&
								subscriptionArr?.subscriptions?.map((el, idx) => {
									return handleRoute(el, idx, "");
								})}
							{SmallNavLink()}
						</Dropdown.Menu>
					</Dropdown>
				</Dropdown>
			</div>
		</section>
	);
};
export default Header;
