import * as yup from "yup";
import { t } from "i18next";
require("yup-phone");

const emailRules = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// Register Validation
const registerValidation = () =>
	yup.object().shape({
		first_name: yup
			.string()
			.min(1, t("register.register-name-match"))
			.required(t("register.register-fname-required")),
		last_name: yup
			.string()
			.min(1, t("register.register-name-match"))
			.required(t("register.register-lname-required")),
		email: yup
			.string()
			.email()
			.matches(emailRules, t("register.register-email-match"))
			.required(t("register.register-email-required")),
		password: yup
			.string()
			.min(6)
			.required(t("register.register-password-required")),
		phone: yup
			.string()
			.phone(t("register.register-phone-match"))
			.required(t("register.register-phone-required")),
	});

// Personal Validation
const personalValidation = (data) =>
	yup.object().shape({
		first_name: yup
			.string()
			.min(1, t("student-profile.profile.validation-name-match"))
			.default(`${data.first_name}`)
			.required(t("student-profile.profile.validation-fname-required")),
		last_name: yup
			.string()
			.min(1, t("student-profile.profile.validation-name-match"))
			.default(`${data.last_name}`)
			.required(t("student-profile.profile.validation-lname-required")),
		phone: yup
			.string()
			.phone(t("student-profile.profile.validation-phone-match"))
			.default(`${data.phone}`)
			.required(t("student-profile.profile.validation-phone-required")),
	});

// Register Validation
const hctValidation = () =>
	yup.object().shape({
		email: yup
			.string()
			.email()
			.matches(emailRules, t("register.register-email-match"))
			.required(t("register.register-email-required")),
		phone: yup
			.string()
			.phone(t("register.register-phone-match"))
			.required(t("register.register-phone-required")),
		preferred_learning_time: yup
			.string()
			.min(1, "Please select at least one time")
			.required("Must select at least one time"),
	});

export { registerValidation, personalValidation, hctValidation };
