import { errorHandler } from "..";
import Axios from "../../Axios";

export default async function ServiceHandler(method, url, data, mapperName) {
	try {
		// let response;
		const request =
			method === "get" ? Axios.get(url, data) : Axios.post(url, data);
		const requestResult = await request;

		if (mapperName !== null) {
			// return ([response, undefined] = mapperName(request?.data));
			return Array.isArray(requestResult?.data?.data)
				? requestResult?.data?.data.map((classItem) => mapperName(classItem))
				: mapperName(requestResult?.data?.data);
		} else {
			return requestResult?.data?.data;
		}
	} catch (e) {
		errorHandler(e.response.status);
		// throw new Error(e.message);
	}
}
