import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import RenewSubscription from "../../components/reusable-components/RenewSubscriptionsButton";
import "./styles.scss";
const Renewal = () => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation();
  return (
    <div className="container d-flex flex-column align-items-center justify-content-center ">
      {/* Renewal Title */}
      <div className="text-center mt-4">
        <p className="mb-0 englease englease__txt--black englease--f-26">
          {t("renewal.title")} <strong> {user?.name}!</strong>
        </p>
      </div>
      {/* Renewal Image */}
      <div className="d-flex align-items-center justify-content-center my-5">
        <i className="imET-Renewal englease__bg--cover Renewal__image"></i>
      </div>
      {/* Renewal Holder Of Description And Button */}
      <div className="d-flex flex-column align-items-center justify-content-center ">
        {/* Renewal Description */}
        <div className="englease englease__txt--black englease--f-14 Renewal__text mb-4">
          <p className="mb-0">{t("renewal.description")}</p>
        </div>
        <div>
          {/* Renewal Button */}
          <div className="mb-2">
            <RenewSubscription />
          </div>
          {/* Renewal Link */}
          <div className="d-flex englease englease__txt--black englease--f-14">
            <span>
              {t("renewal.helper")}
              <NavLink
                className="my-0 ms-1 p-0 englease__txt--main message__decoration"
                to={"/support"}
              >
                {t("renewal.link")}
              </NavLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Renewal;
