import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../utils";
import { submitSurveyAnswer } from "../../services/surveyServices";
import LoaderComponent from "../reusable-components/LoaderComponent";
import ConfirmationModal from "../reusable-components/ConfirmationModal";
import "./styles.scss";

const Survey = ({ surveyArr }) => {
	const { t } = useTranslation();
	const detectLang = getCookie("i18next");
	const [modalShow, setModalShow] = useState(true);
	const [surveyDetail, setSurveyDetail] = useState();
	const [studentSurvey, setStudentSurvey] = useState([]);
	const [studentComment, setStudentComment] = useState("");
	const [surveyQuestions, setSurveyQuestions] = useState([]);

	// Get Results list from service.
	const AssessmentsLvl = async () => {
		const data = require("./data.json");
		detectLang === "en"
			? setSurveyQuestions(data.en.data)
			: setSurveyQuestions(data.ar.data);
	};

	useEffect(() => {
		setSurveyDetail(surveyArr);
	}, [surveyArr]);

	useEffect(() => {
		AssessmentsLvl();
	}, [surveyDetail, surveyQuestions]);

	// with add & remove filter
	const CheckHandler = (e, idx) => {
		let filterList = [];
		const survey = {
			question_id: e.target.id,
			answer_id: idx,
		};
		filterList = studentSurvey?.filter((item) => {
			return item["question_id"] !== e.target.id;
		});
		filterList.push(survey);
		setStudentSurvey(filterList);
	};

	const handleSubmit = async () => {
		const answers = {
			class_id: surveyDetail[0]?.class_id,
			answers: studentSurvey,
			comment: studentComment.trim(),
		};
		const sendSurvey = await submitSurveyAnswer(answers, "");
		if (sendSurvey.success == true) {
			surveyDetail.shift();
			setSurveyDetail(surveyDetail);
			setTimeout(() => {
				setStudentSurvey([]);
				setStudentComment("");
				setSurveyQuestions([]);
			}, 1000);

			surveyDetail.length == 0 && setModalShow(false);
		}
	};

	return (
		<ConfirmationModal
			size="lg"
			confirm={null}
			keyboard={false}
			show={modalShow}
			onHide={() => setModalShow(false)}
		>
			{!surveyQuestions.length ? (
				<LoaderComponent />
			) : (
				<section className={detectLang === "ar" ? "englease-rtl" : "englease"}>
					<div className="survey">
						<div className="row justify-content-center py-4 ">
							{/* Questions Answer [Parent Holder] */}
							<div className="survey__holder col-lg-10 px-3 px-lg-4 englease__bg--white englease__radius--f-4">
								{/* Questions Title And Subtitle [Section] */}
								<div className="py-4 text-center">
									<p className="mb-4 englease__txt--main englease englease--bold englease--f-24">
										{t("survey.title")}
									</p>
									<p className="m-0 englease__txt--muteTxt englease englease--f-16">
										{t("survey.sub-title")}
									</p>
									<p className="m-0 englease__txt--main englease englease--f-16">
										{surveyDetail[0]?.teacher_name}
									</p>
									<p className="m-0 englease__txt--main englease englease--f-16">
										{surveyDetail[0]?.class_name}
									</p>
								</div>
								{/* Questions And Answers [Section] */}
								<div className="survey__section w-100">
									<p className="m-0 englease__txt--black englease englease--bold englease--f-18 pb-3">
										{t("survey.hint")}
									</p>
									<div className="d-flex flex-column align-items-between">
										<div className="survey__questions d-flex flex-column gap-3">
											{surveyQuestions?.map((question, index) => {
												return (
													<div key={index} className="row">
														<p className="mb-4 englease__txt--black englease  englease--f-16">
															{question?.title}
														</p>
														<div className="row justify-content-center text-center mb-4">
															{question?.answers?.map((answer, idx) => {
																return (
																	<div
																		key={idx}
																		onChange={(e) => CheckHandler(e, answer.id)}
																		className={`col-4 col-md-2 py-2 d-flex flex-column gap-4 englease englease--f-16`}
																		style={{ color: `${answer.color}` }}
																	>
																		<input
																			className="englease--click"
																			id={question?.id}
																			type="radio"
																			name={question.id}
																			defaultValue={answer?.id}
																		/>
																		{answer?.value}
																	</div>
																);
															})}
														</div>
													</div>
												);
											})}
										</div>
										<div className="d-flex flex-column gap-3 mb-4">
											<label
												htmlFor="exampleFormControlTextarea1"
												className="form-label"
											>
												{t("survey.student-comment")}
											</label>
											<textarea
												className="form-control"
												id="exampleFormControlTextarea1"
												rows="3"
												value={studentComment}
												onChange={(e) => setStudentComment(e.target.value)}
											></textarea>
										</div>
										<div className="d-flex justify-content-center align-items-center mt-auto">
											<button
												onClick={handleSubmit}
												className={`survey__button px-4 py-2  englease__border--main englease__bg--main englease__txt--white englease__radius--f-8  englease englease--f-14 ${
													surveyQuestions?.length === studentSurvey?.length
														? "englease--click"
														: "englease--unClick"
												}`}
												disabled={
													surveyQuestions?.length == studentSurvey?.length
														? false
														: true
												}
											>
												<span className="englease englease--f-14">
													{t("survey.submit")}
												</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</ConfirmationModal>
	);
};
export default Survey;
