import Axios from "../Axios";
import { errorHandler } from "../utils";

async function support(data) {
	try {
		const user = await Axios.post(`api/contact-us`, data);
		if (user.data.success === true) {
			return user.data;
		} else {
			return user.data;
		}
	} catch (e) {
		// errorHandler(e.response.status);
		return e.response.data;
	}
}
export { support };
