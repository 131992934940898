import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
const Footer = () => {
	const { t } = useTranslation();
	let enterpriseName = localStorage.getItem("corp-name");

	return (
		<>
			{enterpriseName === "Talaaqa" ? (
				<section className="text-start" height="24">
					<img
						src="/assets/images/Talaaqa/nomu-talaaqa-footer.png"
						style={{ height: "5rem" }}
					/>
				</section>
			) : (
				<section className="" height="24">
					<p className="mb-0 englease__txt--muteTxt">
						{t("footer-description-start")} <b>{t("footer-logo")}</b>
						{t("footer-description-end")}
					</p>
				</section>
			)}
		</>
	);
};
export default Footer;
