export const ClassesMapper = (classItem) => {
	const classesObject = {
		id: classItem?.next_class_id,
		duration: classItem?.next_class_duration,
		homework_status: classItem?.homework_status,
		test_status: classItem?.test_status,
		status: classItem?.status,
		name: classItem?.name,
		start_time: classItem?.next_class_start_time,
		lesson_id: classItem?.id,
	};
	return classesObject;
};

export const ClassTestsMapper = (classDetail) => {
	let classTestsObject, checker, tempObj;
	if (Array.isArray(classDetail.tasks)) {
		classTestsObject = {
			...classDetail,
			tasks: { homework: null, test: null, self_study: null },
		};
		if (classDetail?.tasks.length === 0) {
			return classTestsObject;
		} else {
			checker = classDetail.tasks[0].type_name === "homework";
			tempObj = {};
			if (checker === true) {
				tempObj = {
					homework: classDetail.tasks[0],
					test: classDetail.tasks[1],
				};
				Object.assign(classTestsObject.tasks, tempObj);
			} else {
				tempObj = {
					homework: classDetail.tasks[1],
					test: classDetail.tasks[0],
				};
				Object.assign(classTestsObject.tasks, tempObj);
			}
			return Object.assign(classDetail.tasks, classTestsObject);
		}
	} else {
		classTestsObject = { ...classDetail };
	}

	return classTestsObject;
};
