import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import { ThemeContext } from "../../App";
import { useTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import { resetPasswordUser } from "../../services/studentService";
import { resetPasswordValidation } from "../../schema/schemaValidation";
import InputField from "../../components/reusable-components/InputField";
import "./styles.scss";

const ResetPassword = (props) => {
	const { setToken } = props;
	const { t } = useTranslation();
	const theme = useContext(ThemeContext);
	const [toggleShowPassword, setToggleShowPassword] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [message, setMessage] = useState(null);
	const [msgStatus, setMsgStatus] = useState(null);

	const sendGTMEvent = () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		const isSuccessful = params["is_successful"];
		const description = params["plan_desc"];
		const price = params["plan_price"];

		if (
			typeof isSuccessful == "undefined" ||
			typeof description == "undefined" ||
			typeof price == "undefined"
		) {
			return;
		}

		window["dataLayer"].push({
			event: "custom_event",
			category: "Payment",
			action: isSuccessful ? "Successful" : "Failed",
			label: description,
			value: price,
		});
	};

	// TODO Submit Form To APIs
	const handleSubmit = async (values, actions) => {
		const resetPass = {
			...values,
			token: searchParams.get("token"),
		};

		const resetPassword = await resetPasswordUser(resetPass);

		if (resetPassword.success === true) {
			// resetPassword && location.assign("/");
			sendGTMEvent();
			const userInfo = {
				id: resetPassword?.data?.user?.user_id,
				leadId: resetPassword?.data?.user?.lead_id,
				name: resetPassword?.data?.user?.first_name,
				prerecordedLevelId: resetPassword?.data?.user?.prerecorded_level_id
					? resetPassword?.data?.user?.prerecorded_level_id
					: "",
			};

			// if (resetPassword?.redirect) location.assign(resetPassword?.redirect);
			// else {
			localStorage.setItem(
				"pre_order_id",
				resetPassword?.data?.user?.preorder_id
			);
			localStorage.setItem("name", resetPassword?.data?.user?.name);
			localStorage.setItem("email", resetPassword?.data?.user?.email);
			localStorage.setItem(
				"level",
				resetPassword?.data?.user?.level?.abbreviation
			);
			localStorage.setItem("userInfo", JSON.stringify(userInfo));
			localStorage.setItem(
				"enterpriseObj",
				JSON.stringify(resetPassword?.data?.user?.enterprise)
			);
			if (resetPassword?.data?.user?.enterprise?.enterprise_name)
					localStorage.setItem(
						"corp-name",
						resetPassword?.data?.user?.enterprise?.enterprise_name
					);
			setToken(resetPassword?.data?.tokenDetails?.access_token);
			if (
				resetPassword?.data?.user.level === null &&
				resetPassword?.data?.user?.enterprise?.enterpriseStudentStatus !== 2
			) {
				location.assign("/placement-test");
			} else {
				location.assign("/");
			}
			// }
		} else {
			setMsgStatus(false);
			setMessage(resetPassword?.message);
		}
	};
	const handleCloseMsg = () => {
		setMsgStatus(null);
	};
	return (
		<section className="reset-password">
			{/* ? Image Section */}
			<div className="reset-password__image"></div>
			{/* ? Reset New Password Form Section */}
			<section className="reset-password__form-wrapper col col-lg-4">
				{/* <!-- logo --> */}
				<div className=" reset-password__form-logo w-100">
					{/* <!-- logo --> */}
					<img
						src={`${theme?.logo ? theme?.logo : "/assets/images/logooc.webp"}`}
						className="englease__bg--contain"
						alt="englease-logo"
						width="247"
						height="50"
					/>
				</div>
				{/* <!-- title --> */}
				<div className="reset-password__form-title englease--bold englease--f-24">
					{t("reset-password.reset-password")}
				</div>
				{/* <!-- reset-password form --> */}
				<Formik
					initialValues={{ password: "" }}
					onSubmit={handleSubmit}
					validationSchema={resetPasswordValidation}
				>
					{({ values, touched, errors, handleChange }) => (
						<Form className="reset-password__form-holder h-100">
							{/* //? New Password */}
							<div className="position-relative mb-5 ">
								<InputField
									fieldtype={"text-input"}
									label={t("reset-password.password")}
									name="password"
									type={toggleShowPassword ? "text" : "password"}
									id="password"
									className="englease__txt--black englease englease--f-16 p-2 englease__border--muteTxt englease__radius--f-8 englease__bg--light"
								/>
								{/* Show And Hide Password  [Icon]  */}
								<div onClick={() => setToggleShowPassword(!toggleShowPassword)}>
									<img
										src={`${
											toggleShowPassword
												? "/assets/icons/Hidepassword.svg"
												: "/assets/icons/Showpassword.svg"
										}`}
										className="englease__bg--contain englease--click login__password-icon iET-s16"
										alt="englease-Global"
										width="16"
										height="16"
									/>
								</div>
							</div>
							{msgStatus !== null && (
								<div
									className={`reset-password__message--display ${
										msgStatus === true
											? "englease__bg--success--20"
											: "englease__bg--error"
									}
                 position-relative w-100 mb-4 py-3 px-2 englease__radius--f-8`}
								>
									<i
										onClick={() => handleCloseMsg()}
										className={` ${
											msgStatus === true
												? "iET-Success-Message"
												: "iET-Close-Message"
										}
                     englease--click englease__bg--contain iET-s21 mx-2`}
									></i>
									<p className="m-0 englease__txt--black englease englease--f-14 text-start">
										{message}
									</p>
								</div>
							)}
							{/* reset-password Button Submit */}
							<button
								type="submit"
								className="englease__bg--main englease englease--f-16 englease__txt--white reset-password__btn"
							>
								{t("reset-password.reset-password-btn")}
							</button>
						</Form>
					)}
				</Formik>
				{/* // <!-- Go Back To login in --> */}
				<div className="englease--f-14 reset-password--login">
					{t("reset-password.goback")}
					<NavLink className="englease__txt--main englease--bold mx-2" to="/">
						{t("reset-password.login")}
					</NavLink>
				</div>
			</section>
		</section>
	);
};
export default ResetPassword;
